import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Hero from "../components/hero";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SlButton from "../components/button";
import GrayBanner from "../components/grayBanner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import LocalBusinessSchema from "../components/Schema/localBusiness";
import LogoSchema from "../components/Schema/logo";
import CallIcon from "../components/call-icon";
import Layout from "../components/layout";
import AreaMap from "../components/areaMap";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { GatsbyImage } from "gatsby-plugin-image";

const WhoWeAre = () => {
	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "who-we-are" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					whoWeArePageFieldGroups {
						whoWeAreBannerSection {
							heading
							description
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						whoWeAreCtaSection1 {
							heading
							cta1 {
								target
								title
								url
							}
						}
						whoWeAreGoogleMapSection {
							heading
							googleMap
						}
						whoWeAreTeamSection {
							heading
							team1 {
								description
								heading
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							team2 {
								description
								heading
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: { seoFieldGroups, whoWeArePageFieldGroups },
		site,
	} = data;

	const siteUrl = site.siteMetadata.siteUrl;

	const {
		whoWeAreBannerSection,
		whoWeAreCtaSection1,
		whoWeAreGoogleMapSection,
		whoWeAreTeamSection,
	} = whoWeArePageFieldGroups;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: {
					url: "https://www.seymour-locksmiths.co.uk",
					id: "https://www.seymour-locksmiths.co.uk",
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Who we are",
				item: {
					url: "https://www.seymour-locksmiths.co.uk/who-we-are",
					id: "https://www.seymour-locksmiths.co.uk/who-we-are",
				},
			},
		],
	};

	return (
		<Layout>
			<CallIcon />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			{/* <LogoSchema /> */}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/who-we-are`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{whoWeAreBannerSection &&
				!checkPropertiesForNull(whoWeAreBannerSection, ["heading"]) && (
					<Hero
						Tag="section"
						text={
							<SafeHtmlParser
								htmlContent={whoWeAreBannerSection?.description}
							/>
						}
						image={
							whoWeAreBannerSection?.backgroundImage.localFile.childImageSharp
								.gatsbyImageData
						}
						heading={whoWeAreBannerSection?.heading}
						hFontSize="text-5xl"
						button={whoWeAreBannerSection?.cta}
						actions={whoWeAreBannerSection?.actions}
						textCenter={true}
					/>
				)}

			{whoWeAreTeamSection &&
				!checkPropertiesForNull(whoWeAreTeamSection, ["heading"]) && (
					<Container fluid={true} as="section" className="my-24">
						<Row className="mb-20">
							<Col className="d-flex justify-content-center" xs={12}>
								<h3 className="text-3xl mb-3 fw-700 lato">
									{whoWeAreTeamSection?.heading}
								</h3>
							</Col>
							<Col className="d-flex justify-content-center" xs={12}>
								<span className="sl-underline d-block" />
							</Col>
						</Row>
						<Row>
							{whoWeAreTeamSection &&
								!checkPropertiesForNull(whoWeAreTeamSection, ["team1"]) && (
									<Col xs={12} xl={6}>
										<Row className="justify-content-center">
											<Col xs={12} className="d-flex px-0 mb-5">
												<GatsbyImage
													image={
														whoWeAreTeamSection?.team1.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={whoWeAreTeamSection?.team1.image?.altText}
													className="w-100 team-images-jeff"
												/>
											</Col>
											<Col className="d-flex justify-content-center" xs={12}>
												<h2 className="text-4xl lato fw-700 mb-3">
													{whoWeAreTeamSection?.team1?.heading}
												</h2>
											</Col>

											<Col className="d-flex justify-content-center" xs={12}>
												<span className="sl-underline d-block" />
											</Col>
											{whoWeAreTeamSection?.team1?.description && (
												<Col className="mt-5" xs={11} sm={10}>
													<SafeHtmlParser
														htmlContent={
															whoWeAreTeamSection?.team1?.description
														}
													/>
												</Col>
											)}
										</Row>
									</Col>
								)}
							{whoWeAreTeamSection &&
								!checkPropertiesForNull(whoWeAreTeamSection, ["team2"]) && (
									<Col xs={12} xl={6}>
										<Row className="justify-content-center">
											<Col xs={12} className="d-flex px-0 mb-5">
												<GatsbyImage
													image={
														whoWeAreTeamSection?.team2.image?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={whoWeAreTeamSection?.team2.image?.altText}
													className="w-100 team-images-jeff"
												/>
											</Col>
											<Col className="d-flex justify-content-center" xs={12}>
												<h2 className="text-4xl lato fw-700 mb-3">
													{whoWeAreTeamSection?.team2?.heading}
												</h2>
											</Col>

											<Col className="d-flex justify-content-center" xs={12}>
												<span className="sl-underline d-block" />
											</Col>
											{whoWeAreTeamSection?.team2?.description && (
												<Col className="mt-5" xs={11} sm={10}>
													<SafeHtmlParser
														htmlContent={
															whoWeAreTeamSection?.team2?.description
														}
													/>
												</Col>
											)}
										</Row>
									</Col>
								)}
						</Row>
					</Container>
				)}

			{whoWeAreCtaSection1 &&
				!checkPropertiesForNull(whoWeAreCtaSection1, ["team2"]) && (
					<GrayBanner>
						<Row className="justify-content-center">
							<Col xs={11} sm={10}>
								<Row className="align-items-center">
									<Col xs={12} xl={7}>
										<h4 className="text-2xl lato mb-4 wwa-banner">
											{whoWeAreCtaSection1?.heading}
										</h4>
									</Col>
									{whoWeAreCtaSection1?.cta1 &&
										whoWeAreCtaSection1?.cta1.url && (
											<Col
												xs={12}
												xl={5}
												className="d-flex justify-content-center justify-content-xl-end"
											>
												<SlButton
													href={whoWeAreCtaSection1?.cta1.url}
													className="text-lg fw-700"
													variant="primary"
												>
													{whoWeAreCtaSection1?.cta1.title}
												</SlButton>
											</Col>
										)}
								</Row>
							</Col>
						</Row>
					</GrayBanner>
				)}
			{whoWeAreGoogleMapSection &&
				!checkPropertiesForNull(whoWeAreGoogleMapSection, ["heading"]) && (
					<AreaMap
						heading={whoWeAreGoogleMapSection?.heading}
						map={whoWeAreGoogleMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default WhoWeAre;
